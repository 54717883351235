<template>
  <main class="main">
    <nav class="breadcrumb-nav border-0 mb-0">
      <div class="container">
        <!-- <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">Home</router-link>
          </li>
          <li class="breadcrumb-item active">Create an Account</li>
        </ol> -->
      </div>
    </nav>

    <div class="page-content py-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="bg-white br-1 p-3 p-md-5">
              <h5 class="text-primary text-center">
                {{ $t("create_a_new_account") }}
              </h5>
              <hr />
              <form action="#" @submit.prevent="formSubmit">
                <div class="row mb-1">
                  <div class="col-md-6">
                    <h6 class="text-muted">
                      <strong>{{ $t("personal_information") }}</strong>
                    </h6>
                  </div>
                  <div class="col-md-6 d-none d-md-block">
                    <h6 class="text-muted">
                      <strong>{{ $t("login_information") }}</strong>
                    </h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="Naam">{{ $t("form_first_name") }} *</label>
                      <input
                        type="text"
                        class="form-control"
                        id="Naam"
                        name="Naam"
                        :placeholder="$t('form_first_name')"
                        v-model="form.firstname"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label for="last_name">{{ $t("last_name") }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="last_name"
                        name="last_name"
                        :placeholder="$t('last_name')"
                        v-model="form.lastname"
                      />
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input
                        v-model="form.isCompany"
                        type="checkbox"
                        class="custom-control-input"
                        id="is-company"
                      />
                      <label
                        class="custom-control-label pl-2"
                        for="is-company"
                        >{{ $t("register_form_company_question") }}</label
                      >
                    </div>
                    <div v-if="this.getCompany">
                      <div class="form-group">
                        <label for="company_name">{{
                          $t("company_name")
                        }}</label>
                        <input
                          type="text"
                          class="form-control"
                          id="company_name"
                          name="company_name"
                          :placeholder="$t('company_name')"
                        />
                      </div>
                      <div class="form-group">
                        <label for="kvknumber">KvK-{{ $t("number") }}</label>
                        <input
                          type="text"
                          class="form-control"
                          id="kvknumber"
                          name="kvknumber"
                        />
                      </div>
                      <div class="form-group">
                        <label for="btwnumber">BTW-{{ $t("number") }}</label>
                        <input
                          type="text"
                          class="form-control"
                          id="btwnumber"
                          name="btwnumber"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 d-block d-md-none my-3">
                    <p>
                      <strong>{{ $t("login_information") }}</strong>
                    </p>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="email">{{ $t("email_address") }} *</label>
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        name="email"
                        :placeholder="$t('email')"
                        v-model="form.email"
                        :state="form.emailState"
                        required
                      />
                      <b-form-invalid-feedback id="email-feedback">
                        {{ form.emailError }}
                      </b-form-invalid-feedback>
                    </div>
                    <div class="form-group">
                      <label for="password">{{ $t("password") }}</label>
                      <input
                        type="password"
                        class="form-control"
                        id="password"
                        name="password"
                        v-model="form.password"
                        :state="form.passwordState"
                      />
                    </div>
                    <p>Wachtwoordsterkte: Geen wachtwoord</p>
                    <div class="form-group">
                      <label for="confirm_password">{{
                        $t("confirm_password")
                      }}</label>
                      <input
                        type="password"
                        class="form-control"
                        id="confirm_password"
                        name="confirm_password"
                        v-model="form.password_confirm"
                        :state="form.password_confirmState"
                      />
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        v-model="form.is_subscribed"
                        id="newsletter"
                      />
                      <label
                        class="custom-control-label pl-2"
                        for="newsletter"
                        >{{ $t("register_for_our_newsletter") }}</label
                      >
                    </div>
                  </div>
                </div>
                <div class="row mt-0 mt-md-2">
                  <div class="col-md-12">
                    <div
                      class="form-footer justify-content-end justify-content-md-center"
                    >
                      <button type="submit" class="btn btn-success">
                        <span>{{ $t("create_account") }}</span>
                        <!-- <i class="icon-long-arrow-right"></i> -->
                      </button>
                    </div>
                  </div>
                  <div
                    class="col-md-12 d-flex justify-content-end justify-content-md-start mt-2 mt-md-0"
                  >
                    <router-link to="/">
                      <font-awesome-icon
                        class="text-primary"
                        icon="fa-solid fa-angle-left"
                      />
                      {{ $t("continue_shopping") }}</router-link
                    >
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { checkEmailExist } from "@storefront/core/data-resolver/user";
import config from "@config";

export default {
  data() {
    return {
      useremail: "",
      thanksActive: false,
      showFullAddress: true,
      showManual: true,
      typeTimer: null,
      foundAddresses: [],
      foundAddress: "",
      selectedAddress: null,
      isValidVatNumber: false,
      showVatMessage: "",
      showVatCheck: false,
      isInvoiceCompany: false,
      isValidInvoiceVatNumber: false,
      showFullInvoiceAddress: false,
      invoiceAddressTimer: null,
      foundInvoiceAddresses: [],
      foundInvoiceAddress: "",
      selectedInvoiceAddress: null,
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      regExNlPhone:
        /^((\+|00)31[1-9][0-9]{8}||0[1-9][0-9][- ]?[1-9][0-9]{6}||0[1-9][0-9]{2}[- ][1-9][0-9]{5}||06[- ]?[1-9][0-9]{7})$/,
      regExBePhone:
        /^(((\+|00)32\s?|0)(\d\s?\d{3}|\d{2}\s?\d{2})(\s?\d{2}){2}||((\+|00)32\s?|0)4(60|[789]\d)(\s?\d{2}){3})$/,
      regExDePhone:
        /^((\+|00)49[1-9][0-9]{9}||0[1-9][0-9]{9}||015[1-9][0-9]{8}||01(6|7)[1-9][0-9]{7,8}||(\+|00)491(6|7)[1-9][0-9]{7,8})$/,
      regExGenPhone: /^(\+|00)[1-9][0-9]+$/,
      form: {
        email: "",
        emailState: null,
        emailError: "",
        emailTimer: null,
        password: "",
        passwordState: null,
        passwordError: "",
        passwordTimer: null,
        password_confirm: "",
        password_confirmState: null,
        password_confirmError: "",
        password_confirmTimer: null,
        isCompany: false,
        company: "",
        companyState: null,
        companyError: "",
        companyTimer: null,
        firstname: "",
        firstnameState: null,
        firstnameError: "",
        firstnameTimer: null,
        lastname: "",
        lastnameState: null,
        lastnameError: "",
        lastnameTimer: null,
        invoice: {
          // isCompany: true,

          // company: "",
          // companyState: null,
          // companyError: "",
          // companyTimer: null,
          // firstname: "",
          // firstnameState: null,
          // firstnameError: "",
          // firstnameTimer: null,
          // lastname: "",
          // lastnameState: null,
          // lastnameError: "",
          // lastnameTimer: null,
          // coc_number: "",
          // coc_numberState: null,
          // coc_numberError: "",
          // coc_numberTimer: null,
          // taxvat: "",
          // taxvatState: null,
          // taxvatError: "",
          // taxvatTimer: null,
          address: {
            country_code: "",
            // postcode: "",
            // postcodeState: null,
            // postcodeError: "",
            // postcodeTimer: null,

            // street: "",
            // streetDisplay: "",
            // streetDisplayState: null,
            // streetDisplayError: "",
            // streetDisplayTimer: null,

            // house_number: "",
            // house_numberState: null,
            // house_numberError: "",
            // house_numberTimer: null,

            // addition: "",
            // city: "",
            // cityState: null,
            // cityError: "",
            // cityTimer: null,

            // telephone: "",
            // telephoneState: null,
            // telephoneError: "",
            // telephoneTimer: null,
            // default_billing: true,
            // default_shipping: true,
          },
        },
        address: {
          country_code: "",
        },
        is_subscribed: false,
        checkbox2: false,
        manual: false,
      },
    };
  },
  mounted() {
    if (typeof config.customers.defaultCountry != "undefined") {
      this.form.address.country_code = config.customers.defaultCountry;
      this.form.invoice.address.country_code = config.customers.defaultCountry;
    }
    if (config.postcode_validation.enabled == false) {
      this.manual = true;
      this.showManual = false;
    }
    if (config.vat_validation.enabled == false) {
      this.showVatCheck = false;
    }
  },
  created() {
    this.form.email = this.$route.params.useremail;
  },
  computed: {
    getCompany() {
      return this.form.isCompany;
    },
    Email() {
      return this.form.email;
    },
    Password() {
      return this.form.password;
    },
    PasswordConfirm() {
      return this.form.password_confirm;
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
  methods: {
    validateFirstname() {
      if (this.form.firstname == "") {
        this.form.firstnameState = false;
        this.form.firstnameError = this.$t("firstname_required");
      } else {
        this.form.firstnameState = true;
      }
    },
    validateLastname() {
      if (this.form.lastname == "") {
        this.form.lastnameState = false;
        this.form.lastnameError = this.$t("lastname_required");
      } else {
        this.form.lastnameState = true;
      }
    },
    async validateEmail() {
      if (this.form.email) {
        if (this.form.email.length == 0) {
          this.form.emailState = false;
          this.form.emailError = this.$t("email_is_required_field");
          return false;
        } else if (!this.emailReg.test(this.form.email)) {
          this.form.emailState = false;
          this.form.emailError = this.$t("email_is_invalid_field");
          return false;
        } else if (!(await checkEmailExist(this.form.email))) {
          this.form.emailState = false;
          this.form.emailError = this.$t("email_already_exists");
          return false;
        }
        this.form.emailState = true;
        return true;
      } else {
        return false;
      }
    },

    validatePassword() {
      if (this.form.password.length < 8) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/[a-z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (
        this.form.password.match(/[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]/) == null
      ) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/[A-Z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/\d/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else {
        this.form.passwordState = true;
      }
    },
    validateConfirmationPassword() {
      if (
        this.form.password_confirm &&
        this.form.password != this.form.password_confirm
      ) {
        this.form.password_confirmState = false;
        this.form.password_confirmError = this.$t("password_confirm_not_equal");
      } else if (this.form.password_confirm == "") {
        this.form.password_confirmState = false;
        this.form.password_confirmError = this.$t("password_confirm_required");
      } else {
        this.form.password_confirmState = true;
      }
    },
    validateCompany() {
      if (this.form.isCompany == true) {
        if (this.form.company == "") {
          this.form.companyState = false;
          this.form.companyError = this.$t("company_name_required");
        } else {
          this.form.companyState = true;
        }
      } else {
        this.form.companyState = null;
      }
    },
    async formSubmit() {
      //TODO Validation
      let formValid = true;
      // if (this.form.emailState != true) {
      //     this.validateEmail();
      //     if (this.form.emailState != true) {
      //         formValid = false;
      //     }
      // }
      // if (this.form.passwordState != true) {
      //     this.validatePassword();
      //     if (this.form.passwordState != true) {
      //         formValid = false;
      //     }
      // }
      // if (this.form.password_confirmState != true) {
      //     this.validateConfirmationPassword();
      //     if (this.form.password_confirmState != true) {
      //         formValid = false;
      //     }
      // }

      // if (this.form.isCompany == true) {
      //     if (this.form.companyState != true) {
      //         this.validateCompany();
      //         if (this.form.companyState != true) {
      //             formValid = false;
      //         }
      //     }
      //     /*        if (this.form.coc_numberState != true) {
      //       this.validateCOCNumber();
      //       if (this.coc_numberState != true) {
      //         formValid = false;
      //       }
      //     }
      //     if (this.form.taxvatState != true) {
      //       this.validateVAT();
      //       if (this.taxvatState != true) {
      //         formValid = false;
      //       }
      //     }*/
      // }
      // if (this.form.firstnameState != true) {
      //     this.validateFirstname();
      //     if (this.firstnameState != true) {
      //         formValid = false;
      //     }
      // }
      // if (this.form.lastnameState != true) {
      //     this.validateLastname();
      //     if (this.lastnameState != true) {
      //         formValid = false;
      //     }
      // }
      // if (this.form.address.postcodeState != true) {
      //     this.validatePostcode();
      //     if (this.form.address.postcodeState != true) {
      //         formValid = false;
      //     }
      // }
      // if (this.form.address.house_numberState != true) {
      //     this.validateHouseNumber();
      //     if (this.form.address.house_numberState != true) {
      //         formValid = false;
      //     }
      // }
      // if (this.form.address.streetDisplayState != true) {
      //     this.validateStreet();
      //     if (this.form.address.streetDisplayState != true) {
      //         formValid = false;
      //         this.showFullAddress = true;
      //     }
      // }
      // if (this.form.address.telephoneState != true) {
      //     this.validatePhone();
      //     if (this.form.address.telephoneState != true) {
      //         formValid = false;
      //     }
      // }

      // if (this.form.address.cityState != true) {
      //     this.validateCity();
      //     if (this.form.address.cityState != true) {
      //         formValid = false;
      //     }
      // }
      console.log("formValid", formValid);
      if (formValid == true) {
        // this.form.address.street = this.form.address.streetDisplay;
        // if (this.form.address.house_number != "") {
        //     this.form.address.street =
        //         this.form.address.street + " " + this.form.address.house_number;
        // }
        // if (this.form.address.addition != "") {
        //     this.form.address.street =
        //         this.form.address.street + "" + this.form.address.addition;
        // }
        if (this.form.isCompany != true) {
          this.form.company = "";
          // this.form.taxvat = "";
        }

        // Deleted coc_number field
        // const formData = { ...this.form };
        // delete formData.coc_number;

        const retval = await this.$store.dispatch("user2/createAccountV2", {
          account: this.form,
        });
        if (retval == true) {
          const msg = {
            type: "success",
            title: "account_create",
            text: this.$t("account_created"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.$router.push("/login");
        }
      }
      //this.thanksActive = true;
    },
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("create_new_account") },
        { name: "keywords", content: this.$t("create_new_account") },
        { name: "description", content: this.$t("create_new_account") },
      ],
      title: this.$t("create_new_account"),
    };
  },
};
</script>
